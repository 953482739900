<template>
    <div id="app">
        <transition name="slide"><router-view /></transition>
    </div>
</template>
<script>
	import { addMemberAccess,pageBrowsingDuration } from '@/api/website.js'
    export default {
        name: "APP",
		data() {
			return {
				prev_id:null,
				timer: ''
			}
		},
		watch: {
			$route: {
				handler(newName, oldName) {
					if(this.timer){
						clearInterval(this.timer);
					}
					if(Boolean(this.prev_id)){
						this.pageBrowsingDuration(this.prev_id);
					}
					if(newName.path=='/' ) return;
					// if( oldName && oldName.fullPath == '/' ){
					// 	console.log('------------------------------')
					// 	window.localStorage.removeItem('enterTime')
					// }
					// var enterTime = '';
					// if(!window.localStorage.getItem('enterTime')){
					//	enterTime = new Date().getTime();
					//	window.localStorage.setItem('enterTime',enterTime)
					// }
					// var outTime = '';
					// var stayTime = '';
					var prev_id = '';
					var param = '';
					if(newName.query){
						for( var key in newName.query){
							if(param==''){
								param += key+'='+newName.query[key];
							}else{
								param += '&'+key+'='+newName.query[key];
							}
						}
					}
					var prev_param = '';
					var prev_path = '';
					
					if(oldName && oldName.fullPath != '/'){
						//页面刷新
						prev_path = oldName.path;
						if(oldName.query){
							for( var key in oldName.query){
								if(prev_param==''){
									prev_param += key+'='+oldName.query[key];
								}else{
									prev_param += '&'+key+'='+oldName.query[key];
								}
							}
						}
						// outTime = new Date().getTime();
						// var start_time = window.localStorage.getItem('enterTime')
						// stayTime = parseInt(( outTime - start_time ) / 1000 );//停留时间
						// console.log(start_time,outTime,stayTime,99)
						// window.localStorage.setItem('enterTime',outTime)
					}else if(document.referrer){
						//新开页面
						var url = new URL(document.referrer);
						prev_param = decodeURI( url.search.substring(1));
						if(url.pathname.indexOf('/web') == -1){
							prev_path = url.pathname;
						}else{
							prev_path = url.pathname.substring(url.pathname.indexOf('/web')+4)
						}
						// outTime = new Date().getTime();
						// var start_time = window.localStorage.getItem('enterTime')
						// stayTime = parseInt(( outTime - start_time ) / 1000 );//停留时间
						// window.localStorage.setItem('enterTime',outTime)
					}
					
					// window.localStorage.setItem('stayTime',stayTime)
					console.log(newName.path,55)
					// if(!prev_path){
					// 	window.localStorage.removeItem('prev_id')
					// }
					// if(window.localStorage.getItem('prev_id')){
					// 	prev_id = window.localStorage.getItem('prev_id');
					// }
					this.addMemberAccess(newName.path,param,prev_path,prev_param)
				},
				// 代表在wacth里声明了firstName这个方法之后立即先去执行handler方法
				immediate: true
			}
		},
		methods: {
			addMemberAccess(path,param,prev_path,prev_param){
				var click_data = window.localStorage.getItem('click_info');
				window.localStorage.removeItem('click_info');
				addMemberAccess({
					param:param,
					path:path,
					click_data:click_data,
					prev_path:prev_path,
					prev_param:prev_param,
					// prev_id:prev_id
				})
				.then(res=>{
					// this.pageBrowsingDuration(res.data,stayTime)
					this.prev_id = res.data;
					this.timer = setInterval(()=>{
						this.pageBrowsingDuration(res.data);
					},5000)
					// setTimeout(()=>{
					// },5000)
					// window.localStorage.setItem('prev_id',res.data)
				})
				.catch(err=>{
					
				})
			},
			pageBrowsingDuration(id){
				console.log(id,99)
				pageBrowsingDuration({
					id:id
				})
				.then(res=>{
					
				})
				.catch(err=>{})
			},
		},
    }
</script>
<style lang="scss" scoped>
    /*每个页面公共css */
    @import url("assets/styles/iconfont.css");
    @import "assets/styles/main.scss";
</style>
